import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtemaceio.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '2704302',
  schema: 'pbi_dte_maceio',
  groupId: 'd0ff56b4-b5ab-444f-8194-387247b24427',
  analiseGeral: '23e67fce-9656-4b79-ac19-a2cd46e60a31',
  analiseUsuarios: '4fda745f-2ad3-4297-9629-4f54a4b1f5da',
  analiseComunicados: 'e5e55e2f-4529-4b1c-ac0f-ec5b46100bb6',
  analiseProcurador: 'f974eed8-71ae-448f-ac15-735e14e112d2',
  analiseEventos: '17e797b8-30d1-45db-8e5d-bf844f29fb27',
  analiseContribuintes: '0911bd35-ce59-4a08-b529-e4bc9a9695c6'
};
